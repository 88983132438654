import React, { useState } from 'react';
import axios from 'axios';
import config from './config.json';

function DataFetcher() {
    const [message, setMessage] = useState('');
    const [users, setUsers] = useState([]);
    const [groups, setGroups] = useState([]);

    const fetchData = () => {
        axios.get(`${config.app1BaseUrl}/hello`)
            .then(response => setMessage(response.data))
            .catch(error => console.error('Error fetching message:', error));

        axios.get(`${config.app1BaseUrl}/users`)
            .then(response => {
                const usersData = response.data["users"];
                if (usersData) {
                    setUsers(usersData);
                }
            })
            .catch(error => console.error('Error fetching users:', error));

        axios.get(`${config.app1BaseUrl}/groups`)
            .then(response => {
                const groupsData = response.data["groups"];
                if (groupsData) {
                    setGroups(groupsData);
                }
            })
            .catch(error => console.error('Error fetching groups:', error));
    };

    return (
        <div>
            <button onClick={fetchData}>Load Data</button>

            <h2>Message:</h2>
            <ul>
                 {JSON.stringify(message)}
            </ul>
            <h2>Users:</h2>
            <ul>
                {users.map(user => <li key={user}>{user}</li>)}
            </ul>
            <h2>Groups:</h2>
            <ul>
                {groups.map(group => <li key={group}>{group}</li>)}
            </ul>
        </div>
    );
}

export default DataFetcher;
